<template>
  <div
    class="w-full h-full gap-4 py-4 flex flex-col items-center bg-white -px-4 overflow-y-auto invisible-scroolbar"
  >
    <div
      class="w-[94%] py-2 cursor-pointer flex justify-center gap-4 -my-[5px] hover:bg-[#A8D6C14D] rounded items-center -bg-slate-400"
    >
      <span v-html="getSvg('stars')"></span>
      <span
        class="font-[600] text-[#555F6D] text-[18px] leading-[21px] tracking-[2.2%]"
        >Symbol Lookup</span
      >
      <span
        class="text-[#D8A60F] bg-[#FFB3002E] font-[500] rounded-2xl px-3 py-[1px]"
        >New</span
      >
    </div>

    <!-- Stock Quote -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :title="'Stock Quote & Stock Evaluator ®'"
      />
    </div>

    <!-- portfolio management -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="portfolioManagement"
        :title="'Portfolio Management'"
      />
    </div>

    <!-- current article -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="currentArticles"
        :title="'Current Articles'"
      />
    </div>

    <!-- past article -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="pastArticles"
        :title="'Past Articles'"
      />
    </div>

    <!-- website information -->
    <div class="w-full h-auto">
      <LeftBarComp
        :backgroundColor="backgroundColor"
        :btnData="websiteInformation"
        :title="'Website Information'"
      />
    </div>

    <!-- New Features Detail Here -->
    <div
      class="w-[85%] min-h-[350px] rounded-xl flex flex-col gap-5 mt-16 p-4 bg-[#EBF1EE]"
    >
      <div
        class="w-full h-[30px] flex justify-between gap-3 font-inter text-[16px] leading-[14.52px] tracking-[0.2] text-[#6D6D6D]"
      >
        <span class="text-[#101828] font-[600] text-[18px] leading-[20px]"
          >New features available!</span
        >
        <span class="cursor-pointer" v-html="getSvg('cross')"></span>
      </div>
      <div class="w-full h-[240px] justify-between flex flex-col -border">
        <span
          class="w-full flex justify-center items-center font-inter text-[18px] leading-[21px] tracking-[0.2] text-[#475467]"
        >
          Check out the new dashboard view. Pages now load faster.
        </span>
        <div
          class="w-full -border h-[220px] py-4 flex justify-center items-center"
        >
          <span class="!w-full !h-full" v-html="getSvg('newfeatures')"></span>
        </div>
      </div>
      <div
        class="w-full h-[30px] flex gap-4 font-[600] text-[16px] leading-[20px]"
      >
        <span class="cursor-pointer text-[#475467] font">Dismiss</span>
        <span class="text-[#134479] cursor-pointer">What’s new?</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/Leftbar.js";
import LeftBarComp from "../left-bar-components/LeftBarComp.vue";

export default {
  name: "NavBar",

  data() {
    return {
      portfolioManagement: [
        {
          id: 1,
          title: "Watchlist",
          icon: "watchlist",
        },
        {
          id: 2,
          title: "Asset Allocation",
          icon: "assetallocation",
        },
        {
          id: 3,
          title: "Asset Management",
          icon: "assetmanagement",
        },
      ],

      currentArticles: [
        {
          id: 1,
          title: "Overview",
          newTitle: "New",
          icon: "overview",
        },
        {
          id: 2,
          title: "Commentary",
          icon: "commentary",
        },
        {
          id: 3,
          title: "Covered Calls",
          icon: "coveredcalls",
        },
        {
          id: 4,
          title: "Stock Picks",
          icon: "stockpicks",
        },
        {
          id: 5,
          title: "Spreads",
          icon: "spreads",
        },
        {
          id: 6,
          title: "Learning Curve",
          icon: "learningcurve",
        },
        {
          id: 7,
          title: "LEAPS Plays",
          icon: "leapsplays",
        },
        {
          id: 8,
          title: "Homework Followup",
          icon: "homeworkfollowup",
        },
        {
          id: 9,
          title: "Coval Trend Indicator",
          icon: "covaltrendindicator",
        },
        {
          id: 10,
          title: "Technical Talk",
          icon: "technicaltalk",
        },
      ],

      pastArticles: [
        {
          id: 1,
          title: "Technical Talk",
          icon: "technicaltalk",
        },
        {
          id: 2,
          title: "Learning Curve",
          icon: "learningcurve",
        },
        {
          id: 3,
          title: "Glossary",
          icon: "glossary",
        },
      ],

      websiteInformation: [
        {
          id: 1,
          title: "Video Tour",
          icon: "videotour",
        },
        {
          id: 2,
          title: "Upcoming Events",
          icon: "upcommingevents",
        },
        {
          id: 3,
          title: "Contact Us",
          icon: "contactus",
        },
      ],
    };
  },
  props: {
    backgroundColor: String,
  },
  components: {
    LeftBarComp,
  },

  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
  },
};
</script>

<style></style>
