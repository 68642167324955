<template>
    <div class="w-full h-full flex flex-col --py-3 gap-4">
      <h1 class="text-yellow-500">S.I</h1>
    </div>
  </template>
  
  <script>
//   import ChartVue from ".././AppexChart/ChartVue.vue";
//   import { getSvgByName } from "../../assets/svgs/card";
  
  export default {
    name: "watchlistCard",
    components: {
    //   ChartVue,
    },
    data() {
      return {
        
      }
    }
  };
  </script>
  
  <style scoped>
  
  </style>
  