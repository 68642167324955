<template>
  <div class="w-full h-full flex justify-between items-center -bg-white px-8">
    <div
      class="gap-8 w-[36%] xl:min-w-[670px] flex justify-between -bg-slate-300"
    >
      <div v-html="getSvg('logo')"></div>

      <!-- :class=" ? 'border-[#161616] mrborder-[1.5px]' : ''" -->
      <div
        class="flex border border-[#D1D1D1] min-w-[355px] px-1.5 h-[47px] bg-[#FFFFFF] items-center justify-around rounded"
      >
        <span class="cursor-pointer" v-html="getSvg('search')"> </span>
        <input
          class="w-[70%] !text-[14px] text-[#6D6D6D] py-2 -bg-slate-500 focus:outline-none ring-0"
          type="text"
          placeholder="Search ticker or company name"
        />
        <span v-html="getSvg('command')"> </span>
      </div>
    </div>

    <ul class="flex gap-6" ref="scannerDropdown">
      <li
        v-for="(item, index) in btnData"
        @click="isActivePage(item?.title)"
        :key="index"
        :class="
          item?.title === isActive
            ? 'border-b-2 border-[#037950]'
            : 'border-b-2 border-transparent'
        "
      >
        <router-link
          class="flex cursor-pointer text-[#4F4F4F] leading-[21px] whitespace-nowrap --pb-2 text-[14px] font-inter items-center gap-2 h-8"
          :to="{ name: item?.name }"
        >
          <span v-html="getSvg(item?.icon)"></span>
          {{ item?.title }}
          <span v-html="getSvg(item?.dArrow)"></span>
        </router-link>
      </li>
      <!-- scanners dropdown -->
      <div
        v-if="scansDropdown"
        class="h-auto w-[250px] absolute bg-[#F8F8F8] rounded-bl rounded-br border shadow-xl z-[999] top-16"
      >
        <ul>
          <li
            v-for="(item, index) in scanTypeData"
            :key="index"
            @click="isActiveScan(item?.name, item.title)"
          >
            <router-link
              class="flex cursor-pointer border-t px-4 hover:bg-[#E5F3EC] leading-[21px] whitespace-nowrap text-[#555F6D] font-[500] font-inter text-[15px] items-center gap-2 h-10"
              :to="{ name: item?.pagename }"
            >
              <span v-html="getSvg(item.icon)"></span>
              {{ item?.title }}
            </router-link>
          </li>
        </ul>
      </div>
      <!-- articles dropdown -->
      <div
        v-if="articlesDropdown"
        ref="box"
        class="h-auto w-[250px] ml-[137px] absolute bg-[#F8F8F8] rounded-bl rounded-br border shadow-xl z-[999] top-16"
      >
        <ul class="relative" @mouseleave="unSelectArticleType()">
          <li
            v-for="(item, index) in articleType"
            :key="index"
            @mouseover="selectArticleType(item?.name, item.title)"
            class="flex cursor-pointer border-t px-4 hover:bg-[#E5F3EC] leading-[21px] whitespace-nowrap text-[#555F6D] font-[500] font-inter text-[15px] items-center justify-between h-10"
          >
            <!-- article type -->
            <div class="flex items-center gap-2">
              <span v-html="getSvg(item.icon)"></span>
              {{ item?.title }}
            </div>
            <span v-html="getSvg('rarrow')"></span>
          </li>

          <!-- current article -->
          <div
            v-if="currentarticle"
            class="w-full h-auto ml-[249px] rounded-bl rounded-br border shadow-xl top-0 bg-[#F8F8F8] absolute"
          >
            <ul>
              <li
                v-for="(item, index) in currentarticlesData"
                :key="index"
                @click="isActiveScan(item?.name, item.title)"
              >
                <router-link
                  class="flex cursor-pointer border-t px-4 hover:bg-[#E5F3EC] leading-[21px] whitespace-nowrap text-[#555F6D] font-[500] font-inter text-[15px] items-center gap-2 h-10"
                  :to="{ name: item?.pagename }"
                >
                  <span v-html="getSvg(item.icon)"></span>
                  {{ item?.title }}
                </router-link>
              </li>
            </ul>
          </div>

          <!-- past article -->
          <div
            v-if="pastarticle"
            class="w-full h-auto ml-[249px] rounded-bl rounded-br border shadow-xl top-10 bg-[#F8F8F8] absolute"
          >
            <ul>
              <li
                v-for="(item, index) in pastarticlesData"
                :key="index"
                @click="isActiveScan(item?.name, item.title)"
              >
                <router-link
                  class="flex cursor-pointer border-t px-4 hover:bg-[#E5F3EC] leading-[21px] whitespace-nowrap text-[#555F6D] font-[500] font-inter text-[15px] items-center gap-2 h-10"
                  :to="{ name: item?.pagename }"
                >
                  <span v-html="getSvg(item.icon)"></span>
                  {{ item?.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </ul>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/Navbar.js";
// import { useOutsideClick } from "../../directives/clickOutside.js"; // Import the useOutsideClick function from the clickOutside.js module

import { EventBus } from "../../EventBus.js";

export default {
  name: "NavBar",
  data() {
    return {
      isActive: "Scanners",
      isEnter: false,
      dropdownRef: null,
      scansDropdown: false,
      articlesDropdown: false,
      isActiveScanner: "GoodStocks",
      currentarticle: false,
      pastarticle: false,
      btnData: [
        {
          id: 1,
          title: "Scanners",
          icon: "magic",
          dArrow: "dArrow",
        },
        {
          id: 2,
          title: "Articles",
          icon: "article",
          dArrow: "dArrow",
        },
        {
          id: 3,
          title: "Help Center",
          name: "HelpCenter",
          icon: "exclamation",
        },
        {
          id: 4,
          title: "Settings",
          name: "SettingsVue",
          icon: "settings",
        },
        {
          id: 5,
          title: "Sign Out",
          icon: "logout",
        },
      ],
      scanTypeData: [
        {
          id: 1,
          title: "Good Stocks",
          name: "GoodStocks",
          pagename: "ScannerVue2",
          icon: "goodstocksicon",
        },
        {
          id: 2,
          title: "Bad Stocks",
          name: "BadStocks",
          pagename: "ScannerVue2",
          icon: "badstocksicon",
        },
        {
          id: 3,
          title: "Allmost Good Stocks",
          name: "AlmostGoodStocks",
          pagename: "ScannerVue2",
          icon: "allmostgoodstocksicon",
        },
        {
          id: 4,
          title: "Dividend Stocks",
          name: "DividendStocks",
          pagename: "ScannerVue2",
          icon: "dividendstocksicon",
        },
        {
          id: 5,
          title: "Covered Calls Callars",
          name: "CoveredCallsCollars",
          pagename: "ScannerVue2",
          icon: "coveredcalls",
        },
        {
          id: 6,
          title: "Current Trend",
          name: "CurrentTrend",
          pagename: "ScannerVue2",
          icon: "currentuptrendicon",
        },
        {
          id: 7,
          title: "Downside Breakout",
          name: "Downsidebreakout",
          pagename: "ScannerVue2",
          icon: "downsidebreakouticon",
        },
        {
          id: 8,
          title: "Earning Calendar",
          name: "EarningCalendar",
          pagename: "ScannerVue2",
          icon: "earningcalendericon",
        },
        {
          id: 9,
          title: "Elite Covered Calls",
          name: "EliteCoveredCalls",
          pagename: "ScannerVue2",
          icon: "elitecoveredcallsicon",
        },
        {
          id: 10,
          title: "Over Valued Stocks",
          name: "OverValuedStocks",
          pagename: "ScannerVue2",
          icon: "overvaluedstockicon",
        },
        {
          id: 11,
          title: "Stellar Fundamental",
          name: "StellarFundamental",
          pagename: "ScannerVue2",
          icon: "stellarfundamentalicon",
        },
        {
          id: 12,
          title: "Stock Under Twenty",
          name: "StockUnderTwenty",
          pagename: "ScannerVue2",
          icon: "stockundertwentyicon",
        },
        {
          id: 13,
          title: "Three Green Arrows",
          name: "ThreeGreenArrows",
          pagename: "ScannerVue2",
          icon: "threegreenarrowsicon",
        },
        {
          id: 14,
          title: "Three Red Arrows",
          name: "ThreeRedArrows",
          pagename: "ScannerVue2",
          icon: "threeredarrowsicon",
        },
        {
          id: 15,
          title: "Under Valued Stocks",
          name: "UnderValuedStocks",
          pagename: "ScannerVue2",
          icon: "undervaluedstockicon",
        },
        {
          id: 16,
          title: "Upside Breakout",
          name: "UpsideBreakout",
          pagename: "ScannerVue2",
          icon: "upsidebreakouticon",
        },
      ],
      articleType: [
        {
          id: 1,
          title: "Current Articles",
          icon: "currentarticleicon",
        },
        {
          id: 2,
          title: "Past Articles",
          icon: "pastarticleicon",
        },
      ],
      currentarticlesData: [
        {
          id: 1,
          title: "Overview",
          icon: "overview",
          pagename: "ArticleVue",
        },
        {
          id: 2,
          title: "Commentary",
          icon: "commentary",
          pagename: "ArticleVue",
        },
        {
          id: 3,
          title: "Covered Calls",
          icon: "coveredcalls",
          pagename: "ArticleVue",
        },
        {
          id: 4,
          title: "Stock Picks",
          icon: "stockpicks",
          pagename: "ArticleVue",
        },
        {
          id: 5,
          title: "Spreads",
          icon: "spreads",
          pagename: "ArticleVue",
        },
        {
          id: 6,
          title: "Learning Curve",
          icon: "learningcurve",
          pagename: "ArticleVue",
        },
        {
          id: 7,
          title: "LEAPS Plays",
          icon: "leapsplays",
          pagename: "ArticleVue",
        },
        {
          id: 8,
          title: "Homework Followup",
          icon: "homeworkfollowup",
          pagename: "ArticleVue",
        },
        {
          id: 9,
          title: "Coval Trend Indicator",
          icon: "covaltrendindicator",
          pagename: "ArticleVue",
        },
        {
          id: 10,
          title: "Technical Talk",
          icon: "technicaltalk",
          pagename: "ArticleVue",
        },
      ],
      pastarticlesData: [
        {
          id: 1,
          title: "Technical Talk",
          icon: "technicaltalk",
          pagename: "ArticleVue",
        },
        {
          id: 1,
          title: "Learning Curve",
          icon: "learningcurve",
          pagename: "ArticleVue",
        },
        {
          id: 1,
          title: "Glossary",
          icon: "glossary",
          pagename: "ArticleVue",
        },
      ],
    };
  },

  mounted() {
    this.$nextTick(() => {
      document.addEventListener("click", this.handleOutsideClick);
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
    handleOutsideClick(event) {
      if (
        this.$refs.scannerDropdown &&
        !this.$refs.scannerDropdown.contains(event.target)
      ) {
        
        this.scansDropdown = false;
        this.articlesDropdown = false;
      }
    },
    isActivePage(name) {
      this.isActive = name;
      if (name === "Scanners") {
        this.scansDropdown = !this.scansDropdown;
        this.articlesDropdown = false;
      } else if (name === "Articles") {
        this.articlesDropdown = !this.articlesDropdown;
        this.scansDropdown = false;
      }
    },
    selectArticleType(name, title) {
      if (title === "Current Articles") {
        console.log("title", title);
        // alert(title);
        this.currentarticle = true;
        this.pastarticle = false;
      } else if (title === "Past Articles") {
        this.pastarticle = true;
        this.currentarticle = false;
      }
    },

    handleDocumentClick(event) {
      const box = this.$refs.box;
      if (box && !box.contains(event.target)) {
        // Check if `box` exists before accessing `.contains`
        this.isEnter = false;
        // console.log("box is clicked1");
      } else {
        this.isEnter = true;
        // console.log("box is clicked2");
      }
    },

    unSelectArticleType() {
      this.currentarticle = false;

      this.pastarticle = false;
    },

    isActiveScan(name, title) {
      this.isActiveScanner = name;
      this.scansDropdown = false;

      EventBus.$emit("isAcitveScan", this.isActiveScanner, title);
    },
  },
};
</script>

<style scoped>
input::-webkit-input-placeholder {
  font-size: 15px;
  font: 900 !important;
  color: #6d6d6d !important;
}
</style>
