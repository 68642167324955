import Vue from "vue";
import Router from "vue-router";
// import ScannerVue from "../components/ScannerVue/ScannerVue.vue";
import ScannerVue2 from "../components/ScannerVue/ScannerVue2.vue";
import HelpCenter from "@/components/HelpCenter/HelpCenter.vue";
import ArticleVue from "../components/Article/ArticleVue.vue";
import SettingsVue from "@/components/SettingsVue/SettingsVue.vue";

Vue.use(Router);

const router = new Router({
  mode: "history", // Clean URLs without hash (#)
  routes: [
    {
      path: "/", // Default route
      name: "ScannerVue2",
      component: ScannerVue2,
    },
    {
      path: "/ArticleVue", // Article page route
      name: "ArticleVue",
      component: ArticleVue,
    },
    {
      path: "/HelpCenter", // HelpCenter page route
      name: "HelpCenter",
      component: HelpCenter,
    },
    {
      path: "/SettingsVue", // SettingsVue page route
      name: "SettingsVue",
      component: SettingsVue,
    },
  ],
});

export default router;
