import Vue from "vue";
import App from "./App.vue";
import router from "../src/Router/Router"; // Import the router
import "./index.css";

new Vue({
  el: "#app",
  router, // Add the router to your Vue instance
  render: (h) => h(App),
});
