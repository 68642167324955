<template>
  <div class="w-full h-full flex flex-col -bg-red-500">
    <!-- header -->
    <div
      :class="isCardView ? 'h-[63px]' : 'h-[63px]'"
      class="w-full --py-2 gap-4 flex flex-col --pt-[10px] justify-center -bg-slate-500"
    >
      <!-- card view header -->
      <!-- v-if="isCardView" -->
      <div
        class="flex justify-between h-full --mb-[6px] items-center gap-0 -bg-slate-300"
      >
        <!-- left side -->
        <div class="flex flex-col -items-center gap-3">
          <div v-if="isCardView" class="flex hidden gap-2 items-center">
            <span class="text-[#7C7C7C] text-[14px] font-inter font-[400]"
              >Scanners</span
            ><span v-html="getSvg('rightarrow')"></span
            ><span
              class="text-[#292929] text-[14px] font-[600] font-inter leading-[21px]"
              >3 Green Arrows</span
            >
          </div>
          <div class="flex flex-col -items-center gap-1">
            <!-- <span
              v-if="isCardView"
              class="font-[500] text-[25px] font-inter leading-[24px] tracking-[0px]"
              >3 Green Arrows</span
            > -->
            <!-- v-if="!isCardView" -->
            <span
              class="font-[500] text-[23px] gap-2 items-center flex font-inter leading-[24px] tracking-[0px]"
              ><span>Scanners</span>
              <span v-html="getSvg('rightArrow')"></span>
              {{ this.isActive }}</span
            >
            <div class="flex text-[12px] text-[#7C7C7C] leading-[17.7px]">
              <span>Last updated at 12/03 6:00pm UTC</span>
              <!-- <span>Last updated</span>
              <span>02/23/2024</span> -->
            </div>
          </div>
        </div>
        <!-- right side -->
        <div class="flex gap-3">
          <li
            ref="box"
            :class="isEnter ? 'border-[#161616] border-[1.5px]' : 'border'"
            class="flex border sm:w-[150px] xl:min-w-[200px] px-2 h-[48px] items-center !bg-[#f5f4f480] justify-around rounded"
          >
            <span class="cursor-pointer" v-html="getSvg('search')"> </span>
            <input
              class="w-[90%] h-full text-[14px] font-[500] text-[#888888] leading-[21px] bg-[transparent] decoration-none px-0.5 py-2 focus:outline-none ring-0"
              type="text"
              placeholder="Quick Search"
            />
          </li>
          <!-- green background = volume spike -->
          <div class="flex items-center gap-3">
            <div
              class="flex bg-[#d1fae2] rounded gap-4 text-[#037950] min-w-[348px] --mt-6 border-[1.5px] font-inter text-[15px] leading-[16.94px] sm:px-2 xl:px-9 h-[46px] -border-[#037950] items-center"
            >
              <span v-html="getSvg('grExclamation')"></span>
              <span class="text-[#037950] font-[500] font-inter"
                >Green Background = Volume Spike</span
              >
            </div>
          </div>

          <!-- viewTogle btn-->
          <ul class="flex items-center list-none gap-4 underline-offset-0">
            <li
              @click="toggleView('card')"
              :class="
                isCardView
                  ? 'text-[#037950] bg-[#ECFDF4] border-[#037950]'
                  : '!text-[#000000] border-[#E5E5E5]'
              "
              class="flex gap-1 cursor-pointer h-[38px] items-center px-3 --py-1.5 rounded border"
            >
              <span v-html="getSvg('cardview')"></span>
              <span class="hidden">Card View</span>
            </li>
            <li
              @click="toggleView('table')"
              :class="
                !isCardView
                  ? '!text-[#037950] bg-[#ECFDF4] border-[#037950]'
                  : '!text-[#000000] border-[#E5E5E5]'
              "
              class="flex gap-1 cursor-pointer h-[38px] items-center px-3 py-0 rounded border"
            >
              <span v-html="getSvg('tableview')"></span
              ><span class="hidden">Table View</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- table view header -->
      <div
        class="hidden w-full -h-[65%] -flex justify-between items-center gap-3 bg-white"
        v-if="!isCardView"
      >
        <div class="flex items-center gap-3">
          <span
            class="font-[700] text-[30px] font-inter leading-[24px] tracking-[0px]"
            >{{ this.isActiveScanner }}</span
          >
          <div
            class="flex flex-col text-[12px] text-[#7C7C7C] leading-[17.7px]"
          >
            <span>Last updated</span>
            <span>02/23/2024</span>
          </div>
        </div>

        <div
          v-if="!isCardView"
          class="flex bg-[#d1fae2] rounded gap-4 text-[#037950] min-w-[348px] --mt-6 border-[1.5px] font-inter text-[15px] leading-[16.94px] px-9 h-[46px] -border-[#037950] items-center"
        >
          <span v-html="getSvg('grExclamation')"></span>
          <span class="text-[#037950] font-[500] font-inter"
            >Green Background = Volume Spike</span
          >
        </div>
        <!-- <ul class="flex list-none gap-4 underline-offset-0">
          <li
            @click="toggleView('card')"
            :class="
              isCardView
                ? 'text-[#037950] bg-[#ECFDF4] border-[#037950]'
                : '!text-[#000000] border-[#E5E5E5]'
            "
            class="flex gap-1 cursor-pointer items-center px-3 py-1.5 mrrounded-lg border"
          >
            <span v-html="getSvg('cardview')"></span><span>Card View</span>
          </li>
          <li
            @click="toggleView('table')"
            :class="
              !isCardView
                ? '!text-[#037950] bg-[#ECFDF4] border-[#037950]'
                : '!text-[#000000] border-[#E5E5E5]'
            "
            class="flex gap-1 cursor-pointer items-center px-3 py-0 mrrounded-lg border"
          >
            <span v-html="getSvg('tableview')"></span><span>Table View</span>
          </li>
        </ul> -->
      </div>
      <!-- table header -->
      <div
        class="hidden w-full items-center -flex justify-between -h-[45%] --mb-3 bg-white"
      >
        <ul class="flex list-none gap-4 underline-offset-0">
          <li
            @click="toggleView('card')"
            :class="
              isCardView
                ? 'text-[#037950] bg-[#ECFDF4] border-[#037950]'
                : '!text-[#000000] border-[#E5E5E5]'
            "
            class="flex gap-1 cursor-pointer items-center px-3 py-1.5 mrrounded-lg border"
          >
            <span v-html="getSvg('cardview')"></span><span>Card View</span>
          </li>
          <li
            @click="toggleView('table')"
            :class="
              !isCardView
                ? '!text-[#037950] bg-[#ECFDF4] border-[#037950]'
                : '!text-[#000000] border-[#E5E5E5]'
            "
            class="flex gap-1 cursor-pointer items-center px-3 py-0 mrrounded-lg border"
          >
            <span v-html="getSvg('tableview')"></span><span>Table View</span>
          </li>
        </ul>
        <ul class="flex list-none gap-4 items-center">
          <li
            class="flex gap-1 cursor-pointer font-[500] text-[14px] leading-[24px] text-[#000000] items-center px-2 py-[6px] mrrounded-lg border border-[#E5E5E5]"
          >
            <span>Show/Hide Key Data</span
            ><span v-html="getSvg('darrow')"></span>
          </li>
          <li class="text-[#3a3a3a]">
            <span
              >Total
              <span class="text-[#000000] font-[700] text-[14px] leading-[21px]"
                >5.152</span
              >
              stocks found.</span
            >
          </li>
          <li
            ref="box"
            :class="isEnter ? 'border-[#161616] border-[1.5px]' : 'border'"
            class="flex min-w-[348px] px-2 h-[48px] items-center !bg-[#EFEFEF80] justify-around mrrounded-xl"
          >
            <span class="cursor-pointer" v-html="getSvg('search')"> </span>
            <input
              class="w-[90%] h-full text-[16px] font-[500] text-[#888888] leading-[21px] bg-[transparent] decoration-none px-0.5 py-2 focus:outline-none ring-0"
              type="text"
              placeholder="Quick Search in Current Uptrend"
            />
          </li>
        </ul>
      </div>
    </div>

    <!-- main data here -->
    <div
      :class="isCardView ? 'h-[calc(100%-62px)]' : 'h-[calc(100%-63px)]'"
      class="w-full -bg-slate-100"
    >
      <!-- Table view here -->
      <div v-if="!isCardView" class="-container min-w-full h-full relative">
        <div class="min-w-full h-full">
          <div class="--pt-4 h-[98%] min-w-[95%] mrrounded-xl shadow">
            <div
              class="min-w-full h-[calc(100%-52px)] -bg-slate-300 overflow-y-auto border-l border-r invisible-scroolbar rounded"
            >
              <table class="min-w-full -!bg-black relative leading-normal">
                <thead
                  :style="{ backgroundColor: backgroundColor }"
                  class="-bg-[#037950] sticky top-0 mrrounded-tl-xl mrrounded-tr-xl z-[999]"
                  style="z-index: 50 !important"
                >
                  <tr class="-bg-black flex">
                    <th
                      v-for="(column, index) in columns"
                      :key="index"
                      v-show="column.visible"
                      :class="[
                        column?.id == 1 ? '!w-[220px]' : 'w-[158px]',
                        column?.setIcon ? '!w-[219px]' : '',
                      ]"
                      class="--px-5 relative text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-white -uppercase -bg-white"
                    >
                      <div
                        :class="
                          column?.setIcon == 'setIcon'
                            ? 'justify-between flex -bg-slate-500 -w-full px-0'
                            : ''
                        "
                        class="flex items-center gap-2 px-5"
                      >
                        {{ column?.title }}
                        <span
                          v-if="column?.icon"
                          v-html="getSvg(column?.icon)"
                        ></span>
                      </div>
                      <div
                        v-if="column?.title == 'Chart' ? '' : 'hidden'"
                        class="w-[0.2px] h-[38px] bg-black"
                      />

                      <!-- Dropdown start here -->
                      <!-- <div v-if="column.title === 'Chart'" class="bg-black" > -->
                      <!-- Dropdown btn -->

                      <!-- </div> -->
                    </th>
                    <th
                      class="absolute -bg-slate-300 justify-end flex h-full w-[250px] right-0"
                    >
                      <div class="relative flex items-center">
                        <button @click="toggleDropdown" class="mr-5 text-white">
                          <span v-html="getSvg('setIcon')"></span>
                        </button>
                        <!-- Dropdown is here -->
                        <div
                          v-if="showDropdown"
                          class="w-[216px] top-[52px] left-[-175px] border rounded-bl rounded-br shadow-xl overflow-hidden h-[300px] absolute bg-white flex flex-col"
                        >
                          <!-- v-if="!showDropdown" -->
                          <div
                            v-for="(column, index) in columns"
                            :key="index"
                            @click.stop="toggleColumn(index)"
                            :class="
                              column?.title == 'Chart' ||
                              column?.title == 'Ticker'
                                ? 'pointer-events-none bg-[#f3f0f0]'
                                : ''
                            "
                            class="w-full h-full px-3 cursor-pointer border-t flex items-center gap-2 text-black hover:bg-[#E5F3EC]"
                          >
                            <!-- <input
                              type="checkbox"
                              v-model="column.visible"
                              @click.stop="toggleColumn(index)"
                            /> -->
                            <i
                              v-if="column.visible"
                              class="text-[#178f57]"
                              v-html="getSvg('checkBox')"
                            ></i>
                            <i
                              v-if="!column.visible"
                              class="text-[#178f57]"
                              v-html="getSvg('unCheckBox')"
                            ></i>
                            <span class="text-[14px] font-[500]">{{
                              column?.title
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="relative min-w-[95%]">
                  <tr
                    v-for="(row, rowIndex) in rows"
                    :key="rowIndex"
                    class="-bg-black border flex"
                    :class="row?.id == 1 ? 'bg-[#d1fae2]' : 'bg-[white]'"
                  >
                    <!-- ticker -->
                    <td
                      style="width: 220px"
                      scope="col"
                      class="py-2 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
                    >
                      <div class="flex flex-col gap-2 pl-5">
                        <div
                          class="flex text-[18px] leading-[15px] items-center --border-r gap-2"
                        >
                          {{ row?.column1?.title }}
                          <span v-html="getSvg(row?.column1?.shareIcon)"></span>
                        </div>
                        <span
                          class="font-[400] leading-[15px] text-[12px] font-inter"
                          :class="
                            row?.column1?.id == 1
                              ? 'text-[#3f3f3f]'
                              : 'text-[#7A7A7A]'
                          "
                          >{{ row?.column1?.desc }}</span
                        >
                        <div class="flex items-center gap-1">
                          <span
                            v-html="getSvg(row?.column1?.calenderIcon)"
                          ></span>
                          <span class="text-[12px] leading-[15px]">{{
                            row?.column1?.date
                          }}</span>
                        </div>
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- sector -->
                    <td
                      v-if="row?.column2?.isVisible"
                      style="width: 158px"
                      scope="col"
                      class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
                    >
                      <div class="flex flex-col gap-2 px-3">
                        <span
                          class="text-[#1d1d1d] font-[400] leading-[20px] text-[12px] font-inter"
                          >{{ row?.column2?.sector }}</span
                        >
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- industry -->
                    <td
                      v-if="row?.column3?.isVisible"
                      style="width: 158px"
                      scope="col"
                      class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
                    >
                      <div class="flex flex-col gap-2 px-3">
                        <span
                          class="text-[#1d1d1d] font-[400] leading-[20px] text-[12px] font-inter"
                          >{{ row?.column3?.industry }}</span
                        >
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- avg vol -->
                    <td
                      v-if="row?.column4?.isVisible"
                      style="width: 158px"
                      scope="col"
                      class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-end items-center !text-right text-black -uppercase -bg-white"
                    >
                      <div class="flex flex-col -bg-slate-300 gap-2 px-3">
                        <span
                          class="text-[#1d1d1d] font-[550] leading-[15px] text-[15px] font-inter"
                          >{{ row?.column4?.avgVl }}</span
                        >
                        <span
                          class="text-[#7A7A7A] font-[500] leading-[15px] text-[16px] font-inter"
                          >{{ row?.column4?.unit }}</span
                        >
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- ratting -->
                    <td
                      v-if="row?.column5?.isVisible"
                      style="width: 158px"
                      scope="col"
                      class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-end items-center text-left text-black -uppercase -bg-white"
                    >
                      <div
                        class="flex mx-auto flex-col justify-center gap-2 px-3"
                      >
                        <span
                          class="text-[#7A7A7A] font-[400] leading-[15px] text-[12px] font-inter"
                          v-html="getSvg(row?.column5?.stock)"
                        ></span>
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- fv -->
                    <td
                      v-if="row?.column6?.isVisible"
                      style="width: 158px"
                      scope="col"
                      class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-end items-center text-left text-black -uppercase -bg-white"
                    >
                      <div class="flex mx-auto items-center gap-2 px-3">
                        <span
                          v-if="
                            row?.column7?.atp !== null ||
                            row?.column7?.atp > row?.column7?.currentprice
                          "
                          v-html="getSvg('checkIcon')"
                        ></span>
                        <span
                          v-if="
                            row?.column7?.atp == null ||
                            row?.column7?.atp < row?.column7?.currentprice
                          "
                          v-html="getSvg('crossIcon')"
                        ></span>
                        <!-- <span v-html="getSvg(row?.column6?.checkIcon)"></span> -->
                        <!-- <span
                          class="text-[#1d1d1d] font-[500] leading-[14px] text-[14.5px] font-inter"
                          >{{ row?.column6?.fv }}</span
                        > -->
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- atp -->
                    <td
                      v-if="row?.column7?.isVisible"
                      style="width: 158px"
                      scope="col"
                      class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
                    >
                      <div class="flex mx-auto items-center gap-2 px-3">
                        <!-- <span v-html="getSvg(row?.column7?.checkIcon)"></span> -->
                        <!-- <span
                          class="text-[#1d1d1d] font-[500] leading-[14px] text-[14.5px] font-inter"
                          >{{ row?.column7?.atp }}</span
                        > -->
                        <span
                          v-if="
                            row?.column7?.atp !== null ||
                            row?.column7?.atp > row?.column7?.currentprice
                          "
                          v-html="getSvg('checkIcon')"
                        ></span>
                        <span
                          v-if="
                            row?.column7?.atp == null ||
                            row?.column7?.atp < row?.column7?.currentprice
                          "
                          v-html="getSvg('crossIcon')"
                        ></span>
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- optionable -->
                    <td
                      v-if="row?.column8?.isVisible"
                      style="width: 158px"
                      scope="col"
                      class="py-3 w-[auto] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-between items-center text-left text-black -uppercase -bg-white"
                    >
                      <div class="flex mx-auto items-center gap-2 px-3">
                        <span
                          v-if="row?.column8?.optionable == 1"
                          v-html="getSvg('checkIcon')"
                        ></span>
                        <span
                          v-if="row?.column8?.optionable == 0"
                          v-html="getSvg('crossIcon')"
                        ></span>
                        <!-- <span
                          class="text-[#1d1d1d] font-[500] leading-[14px] text-[14.5px] font-inter"
                          >{{ item?.fv }}</span
                        > -->
                      </div>
                      <div class="w-[1px] h-[100%] bg-[#ECECEC]" />
                    </td>
                    <!-- chart -->
                    <td
                      style="width: 240px"
                      scope="col"
                      class="py-3 w-[] text-[14px] font-[500] font-inter tracking-[0.5px] leading-[24px] text-sm flex justify-center -bg-black items-center text-left text-black -uppercase -bg-white"
                    >
                      <div class="flex -bg-green-300 flex-col gap-2 px-3">
                        <div
                          class="w-full -bg-slate-300 h-[70px] flex justify-center items-center"
                        >
                          <!-- <AreaChart /> -->
                          <img
                            class="w-full h-full object-contain"
                            :src="`https://incometradercharts.com/fmgraphttransparent.ashx?h=80&w=200&bw=3&sr=true&s=${row?.column1?.title}&int=true`"
                            alt=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <div
                    v-if="!isLoading && this.rows.length < 1"
                    class="flex justify-center items-center -bg-[#ECECEC] w-full h-[600px]"
                  >
                    <h1 class="text-[20px]">No result found!</h1>
                  </div>
                  <div
                    v-if="isLoading"
                    class="flex bg-white/50 justify-center items-center -absolute fixed top-[171px] w-[82%] h-[620px]"
                  >
                    <span class="loader"></span>
                  </div>
                </tbody>
              </table>
            </div>

            <!-- Table Footer here -->
            <div
              class="flex items-center border mrrounded-bl-xl mrrounded-br-xl h-[52px] px-5 --py-5 -bg-slate-500 xs:flex-row justify-between"
            >
              <div>
                <span
                  >Showing {{ pageStart }} to {{ pageEnd }} of
                  {{ totalResults }} stocks</span
                >
              </div>

              <div class="flex items-center">
                <!-- Pagination Buttons -->

                <!-- Previous Button -->
                <button
                  @click="loadPreviousPage(currentPage - 1)"
                  :disabled="currentPage === 1"
                  class="w-full px-6 py-2 text-base text-gray-600 font-[400] text-[14px] leading-[24px] tracking-[2px] bg-white border mrrounded-l-xl hover:bg-gray-100"
                >
                  Previous
                </button>

                <!-- First Page -->
                <button
                  @click="fetchData(1)"
                  :class="
                    currentPage === 1
                      ? 'text-white bg-[#03985F] hover:bg-[#03985fe3]'
                      : 'text-gray-600 bg-white hover:bg-gray-100'
                  "
                  class="w-full px-4 py-2 text-base border-t border-b"
                >
                  1
                </button>

                <!-- Left Ellipsis -->
                <button
                  v-if="showLeftEllipsis"
                  type="button"
                  class="w-full px-3 py-2 text-base text-gray-600 bg-white cursor-default border border-b hover:bg-gray-100"
                >
                  <span v-html="getSvg('dotted')"></span>
                </button>

                <!-- Dynamic Buttons -->
                <button
                  v-for="button in middleButtons"
                  :key="button"
                  @click="fetchData(button)"
                  :class="
                    button === currentPage
                      ? 'text-white bg-[#03985F] hover:bg-[#03985fe3]'
                      : 'text-gray-600 bg-white hover:bg-gray-100'
                  "
                  class="w-full px-4 py-2 text-base border"
                >
                  {{ button }}
                </button>

                <!-- Right Ellipsis -->

                <button
                  v-if="showRightEllipsis"
                  type="button"
                  class="w-full px-3 py-2 cursor-default text-base text-gray-600 bg-white border-t border-b hover:bg-gray-100"
                >
                  <span v-html="getSvg('dotted')"></span>
                </button>

                <!-- Last Page -->
                <button
                  @click="fetchData(totalPages)"
                  :class="
                    currentPage === totalPages
                      ? 'text-white bg-[#03985F] hover:bg-[#03985fe3]'
                      : 'text-gray-600 bg-white hover:bg-gray-100'
                  "
                  class="w-full px-4 py-2 text-base border"
                >
                  {{ totalPages }}
                </button>

                <!-- Next Button -->
                <button
                  @click="loadNextPage(currentPage + 1)"
                  :disabled="currentPage === totalPages"
                  class="w-full px-5 py-2 text-base text-gray-600 bg-white border-t border-b border-r mrrounded-r-xl hover:bg-gray-100"
                >
                  Next
                </button>

                <!-- <button
                  type="button"
                  :disabled="currentPage === 1 || isLoading"
                  @click="loadPreviousPage"
                  class="w-full px-6 py-2 text-base text-gray-600 font-[400] text-[14px] leading-[24px] tracking-[2px] bg-white border mrrounded-l-xl hover:bg-gray-100"
                >
                  Previous
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 text-base text-white bg-[#03985F] border-t border-b hover:bg-[#03985fe3]"
                >
                  {{ currentPage }}
                </button> -->
                <!-- <button
                  type="button"
                  class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100"
                >
                  2
                </button>
                <button
                  type="button"
                  class="w-full px-3 py-2 text-base text-gray-600 bg-white border-t border-b hover:bg-gray-100"
                >
                  <span v-html="getSvg('dotted')"></span>
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100"
                >
                  24
                </button>
                <button
                  type="button"
                  class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100"
                >
                  25
                </button> -->
                <!-- || !hasMoreData -->
                <!-- <button
                  type="button"
                  :disabled="isLoading"
                  @click="loadNextPage"
                  class="w-full px-5 py-2 text-base text-gray-600 bg-white border-t border-b border-r mrrounded-r-xl hover:bg-gray-100"
                >
                  Next
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- card view here -->
      <div
        v-if="isCardView"
        class="w-full h-full flex justify-center items-center --!py-2 -bg-green-500"
      >
        <div
          class="w-full h-[98%] -bg-red-500 -border overflow-y-auto pb-2 invisible-scroolbar"
        >
          <CardView :cardData="cardData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvgByName } from "../../assets/svgs/CurrentUptrend.js";
import CardView from "../card/CardView.vue";
// import AreaChart from "../AreaChart/AreaChart.vue";
import { EventBus } from "../../EventBus.js";
// import { scannerApi } from "../../apiFetching/api.js"; // Adjust the path based on your file structure
export default {
  name: "DynamicTable",
  data() {
    return {
      // dataCount: 1000, // Example total data count
      // itemsPerPage: 50, // Items per page
      // currentPage: 1, // Current active page

      // old variables
      showDropdown: false,
      isEnter: false,
      hiddenColumns: [],
      isCardView: false,
      isActiveScanner: "GoodStocks",
      isActive: "Good Stocks",
      allData: [],
      currentPage: 1,
      pageStart: 1,
      pageEnd: 50,
      itemsPerPage: 50,
      totalResults: 0,
      isLoading: false,
      hasMoreData: true,
      cardData: [],
      rows: [],
      // rows: [
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "averagestock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "goodstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      //   {
      //     column1: {
      //       id: 1,
      //       title: "IBM",
      //       shareIcon: "share",
      //       desc: "International Business Machine",
      //       calenderIcon: "calender",
      //       date: "08/28/2024",
      //       isVisible: true,
      //     },
      //     column2: {
      //       id: 2,
      //       sector: "Computer and Technology",
      //       isVisible: true,
      //     },
      //     column3: {
      //       id: 3,
      //       industry: "Electronic semiconductors",
      //       isVisible: true,
      //     },
      //     column4: {
      //       id: 4,
      //       avgVl: "45,960,996",
      //       unit: "45M",
      //       isVisible: true,
      //     },
      //     column5: {
      //       id: 5,
      //       stock: "badstock",
      //       isVisible: true,
      //     },
      //     column6: {
      //       id: 6,
      //       checkIcon: "checkbtn",
      //       fv: "104.61",
      //       isVisible: true,
      //     },
      //     column7: {
      //       id: 7,
      //       checkIcon: "checkbtn",
      //       atp: "$104.61",
      //       isVisible: true,
      //     },
      //     column8: {
      //       id: 8,
      //       checkIcon: "checkbtn",
      //       isVisible: true,
      //     },
      //     column9: {
      //       id: 9,
      //       chartIcon: "chart",
      //       isVisible: true,
      //     },
      //   },
      // ],
      columns: [
        {
          id: 1,
          title: "Ticker",
          visible: true,
        },
        {
          id: 2,
          title: "Sector",
          icon: "sector",
          visible: true,
        },
        {
          id: 3,
          title: "Industry",
          visible: true,
        },
        {
          id: 4,
          title: "Avg Vol",
          icon: "rExclamation",
          visible: true,
        },
        {
          id: 5,
          title: "Rating",
          icon: "rExclamation",
          visible: true,
        },
        {
          id: 6,
          title: "FV",
          icon: "rExclamation",
          visible: true,
        },
        {
          id: 7,
          title: "ATP",
          icon: "rExclamation",
          visible: true,
        },
        {
          id: 8,
          title: "Optionable",
          visible: true,
        },
        {
          id: 9,
          title: "Chart",
          setIcon: "setIcon",
          visible: true,
        },
      ],
    };
  },
  components: {
    CardView,
    // AreaChart,
  },
  props: {
    backgroundColor: String,
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },

    async loadNextPage(page) {
      this.goToPage(page);
      this.fetchData(this.currentPage);
      // this.currentPage++;
      this.pageStart += this.itemsPerPage;
      this.pageEnd += this.itemsPerPage;
      console.log("next", this.currentPage);
    },
    loadPreviousPage(page) {
      this.goToPage(page);
      if (this.currentPage > 1) {
        this.fetchData(this.currentPage);
        // this.currentPage--;
        this.pageStart = this.pageStart - this.itemsPerPage;
        this.pageEnd = this.pageEnd - this.itemsPerPage;
      } else {
        this.fetchData(1);
      }
    },

    async fetchData(page) {
      this.goToPage(page);
      this.isLoading = true;
      //http://192.168.0.118:5096/api/ScannerApi/${this.isActiveScanner}?pagestart=${page}&pageend=${this.itemsPerPage},
      try {
        const response = await fetch(
          `https://core.successstockmarket.com/api/ScannerApi/${this.isActiveScanner}?pagestart=${page}&pageend=${this.itemsPerPage}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        this.cards = data;
        console.log("rows before", this.rows);
        this.rows = this.transformData(data);
        this.cardData = this.transformcardData(data);
        console.log("rows after", this.rows);
        console.log("rows cardData", this.cardData);
        this.isLoading = false;
        return data.items; // Adjust based on your API's response structure
      } catch (error) {
        console.error("API error:", error);
        this.isLoading = false;
        return [];
      }
    },

    // new function of goto page

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        console.log("page", page);
        this.currentPage = page;
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleColumn(index) {
      this.columns[index].visible = !this.columns[index].visible;

      this.rows?.map((row) => {
        row[`column${index + 1}`].isVisible = this.columns[index].visible;
      });

      // console.log(
      //   "index visible",
      //   this.rows.map((row) => {
      //     return row[`column${index + 1}`].isVisible;
      //   }),
      //   " index column visible",
      //   this.columns[index].visible
      // );
    },
    toggleView(view) {
      if (view == "card") {
        this.isCardView = true;
      } else {
        this.isCardView = false;
      }
    },

    formatLargeNumber(value) {
      if (value >= 1_000_000_000_000) {
        return (value / 1_000_000_000_000).toFixed(2) + "T";
      } else if (value >= 1_000_000_000) {
        return (value / 1_000_000_000).toFixed(2) + "B";
      } else if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(2) + "M";
      } else if (value >= 10_000) {
        return (value / 1_000).toFixed(2) + "K";
      } else {
        return value.toString();
      }
    },

    transformData(apiData) {
      // Transforms the API data to your frontend structure
      this.totalResults = apiData?.data?.result[0]?.totalResults;
      console.log("totalResults", apiData?.data?.result[0]?.totalResults);
      return apiData?.data?.result[0]?.data?.map((item) => ({
        column1: {
          id: 1,
          title: item?.ticker,
          shareIcon: "share",
          desc: item?.company,
          calenderIcon: "calender",
          date: item?.date ? new Date(item.date).toLocaleDateString() : "N/A",
          isVisible: true,
        },
        column2: {
          id: 2,
          sector: item?.sector,
          isVisible: true,
        },
        column3: {
          id: 3,
          industry: item?.industry,
          isVisible: true,
        },
        column4: {
          id: 4,
          avgVl: item?.avgVol.toLocaleString(),
          unit: `${this.formatLargeNumber(item?.avgVol)}`,
          isVisible: true,
        },
        column5: {
          id: 5,
          stock: item?.rating,
          isVisible: true,
        },
        column6: {
          id: 6,
          checkIcon: "checkbtn",
          fv: item?.fv !== null ? item?.fv.toFixed(2) : "N/A",
          currentprice: item?.currentPrice,
          isVisible: true,
        },
        column7: {
          id: 7,
          checkIcon: "checkbtn",
          atp: item.atp !== 0 ? `$${item?.atp.toFixed(2)}` : "N/A",
          currentprice: item?.currentPrice,
          isVisible: true,
        },
        column8: {
          id: 8,
          optionable: item?.optionable,
          isVisible: true,
        },
        column9: {
          id: 9,
          chartIcon: "chart",
          isVisible: true,
        },
      }));
    },
    transformcardData(apiData) {
      // Transforms the API data to your frontend structure
      // if (!apiData || !apiData.data || !apiData.data.result[0]?.data) {
      //   console.error("Invalid API data structure");
      //   return [];
      // },

      // Extract total results if available
      // this.totalResults = apiData?.data?.result[0]?.totalResults || 0;
      // console.log("Total Results:", apiData);

      // Transform the data array
      return apiData.data.result[0].data.map((item) => ({
        card: {
          ticker: item?.ticker || "N/A",
          shareIcon: "share",
          shareIconbtn: "shareIconbtn",
          bName: item?.company || "Unknown Company",
          price: item?.currentprice ? item.currentprice.toFixed(2) : "N/A",
          currentprice: item?.currentprice
            ? item.currentprice.toFixed(2)
            : "N/A",
          change: "+0.17", // Example: Replace with actual change logic if needed
          cpercent: "(+1.82%)", // Example: Replace with actual percentage logic
          updateTime: "Last updated Yesterday", // Example: Replace with actual date logic
          favIcon: "favIcon",
          removeBtn: "Remove from watchlist",
          cname: "COMPANY",
          name: item?.company || "Unknown Company",
          avgVolName: "AVG VOL",
          avgVol: item?.avgVol ? item.avgVol.toLocaleString() : "N/A",
          analPriceName: "ANALYSTS AVG TARGET PRICE",
          analPrice: item?.atp ? item.atp.toFixed(2) : "N/A",
          nextearnDateName: "NEXT EARNING DATE",
          nextearnDate: new Date(item?.date).toLocaleDateString() || "N/A",
          sname: "Sector",
          sector: item?.sector || "Unknown Sector",
          fairvalName: "FAIR VALUE",
          grcheckbtn: "grcheckbtn",
          fairval: item?.fv ? item.fv.toFixed(2) : "N/A",
          numOfAnalName: "NUMBER OF ANALYSTS",
          numOfAnalists: item?.numOfAnalists, // Example: Add real value if available
          stockRatName: "STOCK RATING",
          srIcon: item?.rating,
          sRating: item?.rating || "N/A",
          rExclamation: "rExclamation",
          darrow: "darrow",
          showMoreBtn: "Show More",
        },
      }));
    },

    handleDocumentClick(event) {
      const box = this.$refs.box;
      if (box && !box.contains(event.target)) {
        // Check if `box` exists before accessing `.contains`
        this.isEnter = false;
        console.log("box is before", this.isEnter);
      } else {
        this.isEnter = true;
        console.log("box is after", this.isEnter);
      }
    },
  },

  computed: {
    // currentPageData() {
    //   // Get the data for the current page
    //   const start = (this.currentPage - 1) * this.itemsPerPage;
    //   return this.allData.slice(start, start + this.itemsPerPage);
    // },

    // page count functions
    totalPages() {
      return Math.ceil(this.totalResults / this.itemsPerPage);
    },
    showLeftEllipsis() {
      return this.currentPage > 3; // Show ellipsis if we're beyond page 3
    },
    showRightEllipsis() {
      return this.currentPage < this.totalPages - 2; // Show ellipsis if we're before the last 2 pages
    },
    middleButtons() {
      const buttons = [];
      const maxButtons = 3; // Max buttons to show between ellipses

      if (this.currentPage <= 3) {
        // Near the beginning
        for (
          let i = 2;
          i <= Math.min(maxButtons + 1, this.totalPages - 1);
          i++
        ) {
          buttons.push(i);
        }
      } else if (this.currentPage >= this.totalPages - 2) {
        // Near the end
        for (let i = this.totalPages - maxButtons; i < this.totalPages; i++) {
          if (i > 1) buttons.push(i); // Avoid duplicating first page
        }
      } else {
        // In the middle
        buttons.push(
          this.currentPage - 1,
          this.currentPage,
          this.currentPage + 1
        );
      }

      return buttons;
    },
  },

  async mounted() {
    EventBus.$on("isAcitveScan", (payload, payload2) => {
      this.isActiveScanner = payload;
      this.isActive = payload2;
      this.fetchData(this.currentPage);
      console.log("isActiveScanner", this.isActiveScanner);
    });

 

    // Load the first page of data when the component mounts
    this.isLoading = true;
    try {
      const initialData = await this.fetchData(this.currentPage);
      if (initialData.length < this.itemsPerPage) {
        this.hasMoreData = false;
      }
      this.allData = [...initialData];
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      this.isLoading = false;
    }

    
  },
  beforeDestroy() {
    // Clean up the listener when the component is destroyed
    EventBus.$off("isAcitveScan");

    
  },
};
</script>

<style scoped>
.dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 10;
}

.dropdown button {
  display: block;
  padding: 5px;
  margin: 0;
  width: 100%;
  text-align: left;
  border: none;
  background: #f9f9f9;
  cursor: pointer;
}

.dropdown button:hover {
  background: #e9e9e9;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #e9faf6;
  border-bottom-color: #065036;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
