<template>
  <div class="w-full h-full flex flex-col">
    <ul
      class="flex justify-center items-center gap-6 h-16 -border-b -bg-green-400"
    >
      <li
        class="cursor-pointer font-[500] border px-4 py-1 flex bg-[#EAEDF0] items-center rounded-full gap-2"
      >
        <span v-html="getSvg('subtraction')"></span>Font size<span
          v-html="getSvg('addition')"
        ></span>
      </li>
      <li
        class="cursor-pointer font-[500] border px-4 py-1 flex bg-[#EAEDF0] items-center rounded-full gap-2"
      >
        Jump to section<span v-html="getSvg('darrow')"></span>
      </li>
    </ul>
    <div
      class="w-full h-[calc(100%-64px)] -bg-slate-300 flex flex-col -justify-center items-center overflow-y-auto"
    >
      <div
        class="flex items-center flex-col w-[70%] h-auto rounded-2xl shadow-inner border -bg-green-400 px-4"
      >
        <div
          class="w-[85%] h-[100px] flex flex-col justify-center items-start -bg-slate-500"
        >
          <span class="font-[550] font-inter text-[#656565] text-[18px]"
            >COMMENTARY</span
          >
          <div class="w-[30px] h-[3.5px] bg-[#656565]"></div>
        </div>
        <div
          class="w-[85%] h-auto flex flex-col justify-center items-start -bg-red-500"
        >
          <h1 class="font-[550] leading-[60px] text-[40px] font-inter">
            A Comprehensive Analysis of Key Stocks
          </h1>
          <div
            class="w-full h-auto flex flex-col justify-center items-start -bg-red-500"
          >
            <p
              class="text-[18px] leading-[28px] tracking-[0.5px] font-inter text-[#4F4F4F]"
            >
              With the volatility in the current market, I thought it would be
              good to look at a few 'rules of thumb' for the short-term trader.
              This is not meant as a comprehensive list; it is just some
              thoughts on our part about trading.
            </p>
          </div>
        </div>

        <div
          class="w-[85%] h-[100px] flex flex-col border-b justify-center items-start -bg-red-500"
        >
          <div
            class="w-full h-20 flex -justify-center items-center gap-10 -bg-red-500"
          >
            <div
              class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
            >
              <span v-html="getSvg('calender')"></span>
              <span>Friday, 27th of January ,2024</span>
            </div>

            <div
              class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
            >
              <span v-html="getSvg('author')"></span>
              <span>Published by Mike Coval</span>
            </div>

            <div
              class="flex items-center gap-2 text-[#3D3D3D] font-[500] text-[16px] leading-[18px] font-inter"
            >
              <!-- <span v-html="getSvg('author')"></span> -->
              <span>5 min read</span>
            </div>
          </div>
        </div>

        <div
          class="w-[85%] h-[100px] flex border-b justify-between items-center -bg-red-500"
        >
          <div
            class="w-auto h-20 flex -justify-center items-center gap-10 -bg-red-500"
          >
            <div
              class="flex items-center gap-2 text-[#CE1717] px-5 py-3 bg-[#CE171714] font-[500] text-[16px] rounded-full leading-[18px] font-inter"
            >
              <span v-html="getSvg('playbtnicon')"></span>
              <!-- <span v-html="getSvg('pausebtnicon')"></span> -->
              <span>Play this article</span>
            </div>

            <div
              class="flex items-center gap-2 text-[#037950] px-5 py-3 font-[500] text-[16px] bg-[#03795014] rounded-full leading-[18px] font-inter"
            >
              <span v-html="getSvg('printbtnicon')"></span>
              <span>Print this article</span>
            </div>
          </div>

          <div
            class="w-auto h-20 flex -justify-center items-center gap-10 -bg-red-500"
          >
            <div
              class="flex items-center gap-2 text-[#CE1717] px-4 py-4 bg-[#F9F9F9] font-[500] text-[16px] rounded-lg leading-[18px] font-inter"
            >
              <span v-html="getSvg('copybtnicon')"></span>
            
            </div>

            <div
              class="flex items-center gap-2 text-[#037950] px-4 py-4 font-[500] text-[16px] bg-[#F9F9F9] rounded-lg leading-[18px] font-inter"
            >
              <span v-html="getSvg('uploadbtnicon')"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//   import ChartVue from ".././AppexChart/ChartVue.vue";
import { getSvgByName } from "../../assets/svgs/article";

export default {
  name: "watchlistCard",
  components: {
    //   ChartVue,
  },
  data() {
    return {};
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name);
    },
  },
};
</script>

<style scoped></style>
