<template>
    <div class="w-full h-full flex justify-between items-center bg-white px-10">
      <div class="w-[30%] flex flex-col gap-3 justify-between font-[400] text-[12px] leading-[14.52px] tracking-[0.2] text-[#6D6D6D]">
       
        <span class="text-[14px] text-[#6D6D6D]">Design and Developed By <span class="text-[#6D6D6D]">Dimensional Sys</span></span>
      </div>
      <div class="flex items-center">
        <span class="text-[#6D6D6D] text-[14px]">Copyright © 2024 All rights reserved.</span>
      </div>
  
      <ul class="-w-[35%] gap-3 flex justify-between">
        <li v-for="(item, index) in btnData" :key="index" class="flex cursor-pointer text-[#6D6D6D] text-[14px] items-center gap-2 h-10">
          <span v-html="getSvg(item?.icon)"></span>
          {{ item?.title }}
          <span v-html="getSvg(item?.dArrow)"></span>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { getSvgByName } from "../../assets/svgs/Navbar.js";
  
  export default {
    name: "NavBar",
  
    data() {
      return {
        btnData: [
          {
            id: 1,
            title: "Home",
           
          },
          {
            id: 2,
            title: "Privacy Policy",
            
          },
          {
            id: 3,
            title: "Terms & Conditions",
            
          },
          {
            id: 4,
            title: "Risk Disclosure",
          },
          {
            id: 5,
            icon: "fbook"
          },
          {
            id: 5,
            icon: "ytube"
          },
          {
            id: 5,
            icon: "lin"
          },
        ],
      };
    },
  
    methods: {
      getSvg(name) {
        return getSvgByName(name);
      },
    },
  };
  </script>
  
  <style></style>
  